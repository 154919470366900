import icons, { getIcon } from './icons.js'

export const size = {
    "TRANSITIVE": 2,
    'ASSOCIATIVE': 1,
    'PERMISSIONS': 1,
    'RESOURCE': 1,   
}

const cache = {}
const badge = (n, m) => {

    if (!(Object.keys(cache).includes(m)))
        cache[m] = {}

    if (!(n.data().type in cache[m])) {

        let svg = decodeURIComponent(n.style("background-image")).split('<svg ')
        let vb = svg[1].match(/viewBox="([-\.\d]+) ([-\.\d]+) ([-\.\d]+) ([-\.\d]+)"/i)

        if (vb === null || vb.length < 4)
            return n.style("background-image")

        vb = vb.splice(1, 4).map(i => parseFloat(i))

        const width = 2 * vb[2] * 0.0125
        const length = 9 * vb[3] * 0.0125

        const radius = 0.5 * length + 1.5 * width
        const dx = vb[0] + vb[2] - 2 * radius;
        const dy = vb[1] + radius;

        const suffix = `<g fill="#FFF">` +
            `<circle stroke="white" stroke-width="${radius/5}" fill="${(m === "collapsible") ? "#6cae3e" : "black"}"` +
            `   cx="${dx + Math.max(length, width) / 2}" ` +
            `   cy="${dy + Math.max(length, width) / 2}" r="${radius}"/>` +
            `<rect x="${dx}" y="${dy + Math.abs(length - width) / 2}" width="${length}" height="${width}"/>` +
            ((m === "collapsible") ?
                `<rect x="${dx + Math.abs(length - width) / 2}" y="${dy}" width="${width}" height="${length}"/>` :
                ""
            ) + `</g>`

        cache[m][n.data().type] = 'data:image/svg+xml;utf8,' + encodeURIComponent(
            '<svg ' +
            svg[1].replace("</svg>", suffix + "</svg>"));
    }

    return cache[m][n.data().type]
}

export default {

    graph: {

        style: [{
            selector: 'node',
            style: {
                'background-color': 'white',
                'border-color': 'black',
                'border-width': 0.2,
                'color': 'white',
                'font-family': 'Roboto Mono, monospace',
                'font-size': '12px',
                'height': 75,
                'label': 'data (name)',
                'text-background-color': 'black',
                'text-background-opacity': '1',
                'text-background-padding': '2px',
                'text-background-shape': 'roundrectangle',
                'text-halign': 'center',
                'text-max-width': '160px',
                'text-valign': 'bottom',
                'text-wrap': 'ellipsis',
                'width': 75
            }
        },
        {
            selector: 'edge',
            style: {
                'curve-style': 'bezier',
                'font-family': 'Roboto Mono, monospace',
                'font-size': '10x',
                'font-size': 0,
                'line-color': "#999999",
                'target-arrow-color': "#999999",
                'target-arrow-shape': 'triangle',
                'text-max-width': '50px',
                'text-rotation': 0,
                'text-wrap': 'ellipsis',
                'width': function (e) {
                    return e.classes().filter(c =>
                        Object.keys(size).includes(c)
                    ).map(c => size[c]).concat(1)[0]
                },
                'z-index': '3'
            }
        },
        {
            selector: 'node',
            style: {
                'background-width': 50,
                'background-height': 50,
                'background-fit': 'none',
                'background-position-x': '50%', 
                'background-position-y': '50%',
                'background-repeat': 'no-repeat',
                'background-image': function (n) {
                    return getIcon(n.data().type);
                },
            }
        },      
        {
            selector: function (e) {
                return (e.classes().includes("TRANSITIVE") &&
                    Object.keys(e.source().data("properties")
                    ).includes("PermissionsBoundary"))
            },
            style: {
                'line-style': 'dashed'
            }
        },
        {
            selector: 'node.ValrApplication',
            style: {
                'border-color': '#1E4DF2',
                'border-width': 0.6,
            }
        },
        {
            selector: 'edge',
            style: {
                'label': 'data (name)',
                'font-size': '10px',
                'font-weight': 'bold',
                'opacity': 1,
                'text-background-color': 'white',
                'text-background-opacity': '1',
                'text-max-width': '1000px',
                'text-rotation': 'autorotate',
                'text-rotation': 0,
                'text-wrap': 'none',
                'width': function (e) {
                    const scale = 1.75;
                    return e.classes().filter(c =>
                        Object.keys(size).includes(c)
                    ).map(c => size[c] * scale).concat(scale)[0]
                },
                'z-index': 10
            }
        },
        {
            selector: 'edge.ASSOCIATIVE, edge.RESOURCE',
            style: {
                'line-style': 'dotted',
            }
        },
        {
            selector: 'edge.ATTACK',
            style: {
                'line-color': 'maroon',
                'line-style': 'dashed',
            }
        },
        {
            selector: 'edge.ATTACK',
            style: {
                'line-color': 'maroon',
                'line-style': 'dashed',
            }
        },
        {
            selector: 'edge.PERMISSIONS',
            style: {
                "line-fill": "linear-gradient",
                'color': 'black',
                'font-size': '10px',
                'font-weight': 'bold',
                'label': 'data (name)',
                'line-gradient-stop-colors': ['gold', 'green'],
                'text-background-color': 'White',
                'text-background-opacity': '1',
                'text-background-padding': '0px',
                'text-max-width': '1000px',
                'text-rotation': 'autorotate'
            }
        },

        {
            selector: 'node.selected',
            style: {
                "underlay-color" : "black",
                "underlay-padding" : "0.6px",
                "underlay-opacity" : "1",
                "underlay-shape": "ellipse",
                'z-index': 4
            }
        },
        {
            selector: 'edge.selected',
            style: {
                'opacity': '1',
                'width': function (e) {
                    const scale = 1.5;
                    return e.classes().filter(c =>
                        Object.keys(size).includes(c)
                    ).map(c => size[c] * scale).concat(scale)[0]
                },
                'z-index': 4,
            }
        },
        {
            selector: '.unselected',
            style: {
                'font-size': '0',
                'opacity': 0.1,
                'z-index': 0
            }
        },
        {
            selector: 'edge.hover',
            style: {
                'font-size': '10px',
                'font-weight': 'bold',
                'opacity': 1,
                'text-background-color': 'white',
                'text-background-opacity': '1',
                'text-max-width': '1000px',
                'text-rotation': 'autorotate',
                'text-rotation': 0,
                'text-wrap': 'none',
                'width': function (e) {
                    const scale = 1.75;
                    return e.classes().filter(c =>
                        Object.keys(size).includes(c)
                    ).map(c => size[c] * scale).concat(scale)[0]
                },
                'z-index': 10
            }
        },
        {
            selector: 'node.hover',
            style: {
                'font-size': '12px',
                'height': 100,
                'opacity': 1,
                'text-wrap': 'none',
                'width': 100,
                'z-index': 10
            }
        },
        {
            selector: 'node.Generic',
            style: {
                'border-color': "green",
                'border-style': "dashed",
                'label': '',
                'opacity': 0.7
            }
        },
        {
            selector: 'node.expandible',
            style: {
                'background-image': (n) => badge(n, "collapsible")
            }
        },
        {
            selector: 'node.unexpandible',
            style: {
                'border-color': "silver",
                'border-width': 2
            }
        },
        {
            selector: 'node.collapsible',
            style: {
                'background-image': (n) => badge(n, "expandible")
            }
        },
        {
            selector: 'node.context-menu',
            style: {
                'label': ""
            }
        }],
        layout: {
            animate: true,
            animateFilter: function (node, i) { return true; },
            animationDuration: 250,
            animationEasing: undefined,
            boundingBox: undefined,
            edgeSep: undefined,
            edgeWeight: function (edge) { return 1; },
            fit: true,
            minLen: function (edge) { return 1; },
            name: 'dagre',
            nodeDimensionsIncludeLabels: true,
            nodeSep: undefined,
            padding: 40,
            rankDir: 'BT',
            rankSep: undefined,
            ranker: 'longest-path',
            ready: function () { },
            spacingFactor: 1.5,
            stop: function () { },
            transform: function (node, pos) { return pos; }
        },
    }
}
