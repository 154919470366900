<template>
  <v-dialog v-if="mounted" persistent max-width="800" v-model="form.enabled">
    <!-- Database connection form -->
    <v-card :disabled="form.loading && page == 0">
      <v-card-title>Database settings</v-card-title>
      <v-card-subtitle>{{ form.values.status }}</v-card-subtitle>
      <div v-if="page == 0">
        <v-card-text class="mt-5 px-12">
          <v-form class="db-settings" ref="form" :value="test.value">
            <v-text-field
              :placeholder="form.uri_placeholder"
              :append-icon="test.icons[test.uri]"
              :rules="rules.uri"
              v-model="form.values.uri"
              label="URI"
              outlined
              required
            ></v-text-field>
            <v-text-field
              :rules="rules.credentials"
              v-model="form.values.username"
              :append-icon="test.icons[test.credentials]"
              placeholder="neo4j"
              label="Username"
              outlined
              required
            ></v-text-field>
            <v-text-field
              :type="form.password_masked ? 'password' : 'text'"
              :append-icon="test.icons[test.credentials]"
              :rules="rules.credentials"
              @focus="form.password_masked = false"
              @blur="form.password_masked = true"
              v-model="form.values.password"
              placeholder="****"
              label="Password"
              outlined
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions class="px-5">
          <v-btn text :disabled="!test.value" @click="db_settings_close()"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn outlined @click="page = 2"
            ><v-icon>mdi-cog-outline</v-icon></v-btn
          >
          <v-btn outlined @click="db_settings_test()" :loading="test.busy"
            >Test</v-btn
          >
          <v-btn
            outlined
            @click="db_settings_apply()"
            :loading="form.loading"
            :disabled="!test.value"
            >Load</v-btn
          >
        </v-card-actions>
      </div>

      <!-- Empty database helper -->
      <div v-else-if="page == 1">
        <v-card-title class="justify-center"
          >Hold up, this database appears to be empty...</v-card-title
        >
        <v-card-subtitle class="text-center"
          >You'll need to populate it with something before
          continuing</v-card-subtitle
        >
        <v-card-text>
          <v-row class="ma-2">
            <v-col />
            <v-col cols="11">
              <div class="mb-2">
                You can run the
                <a href="https://lyft.github.io/cartography/install.html"
                  >ingestor</a
                >
                (to load an environment to explore):
              </div>

              <v-card
                outlined
                color="#f6f8fa"
                class="pa-2"
                style="font-size: 11px"
              >
                cartography --selected-modules=gcp \<br />
                &nbsp;&nbsp;&nbsp;&nbsp;--neo4j-uri=$(NEO4J_URI) \<br />
                &nbsp;&nbsp;&nbsp;&nbsp;--neo4j-password-env-var=NEO4J_PASSWORD
                \ <br />
                &nbsp;&nbsp;&nbsp;&nbsp;--neo4j-user=$(NEO4J_USER) \<br />
                &nbsp;&nbsp;&nbsp;&nbsp;--gcp-requested-syncs=iam,cloudasset
              </v-card>
            </v-col>
            <v-col />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="page = 0">Back</v-btn>
          <v-spacer></v-spacer>
          <v-btn outlined @click="db_settings_apply()" :loading="form.loading"
            >Check Again</v-btn
          >
        </v-card-actions>
      </div>

      <!-- Initialize database helper -->
      <div v-show="page == 2">
        <!-- Editor (adapted from https://github.com/neo4j-contrib/cypher-editor) -->
        <div class="mt-n3 px-10">
          <div style="width: 100%" class="text-right mt-n5"></div>
          <v-card-text
            id="db-connection-query"
            @input="editor.settings.value = editor.value.getValue()"
          />
        </div>

        <v-card-actions>
          <v-btn text @click="page = 0">Discard</v-btn>
          <v-spacer></v-spacer>
          <v-btn outlined @click="db_query_apply()" :loading="form.loading"
            >Save</v-btn
          >
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import * as CypherCodeMirror from "@/codemirror-cypher/cypher-codemirror.min.js";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/lint.css";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/display/autorefresh";

export default {
  name: "Database",
  data: function () {
    return {
      page: 0,
      form: {
        enabled: true,
        values: {
          uri: `bolt://${new URL(location.href).host.split(":")[0]}:7687`,
          username: this.neo4j.auth.username,
          password: this.neo4j.auth.password,
          preload:
            "// Overwrite UI preload query:\nMATCH (resource) RETURN (resource) LIMIT 100",
          status: "Disconnected",
        },
        uri_placeholder: `bolt://${
          new URL(location.href).host.split(":")[0]
        }:7687`,
        password_masked: true,
        loading: false,
      },
      editor: {
        enabled: true,
        value: null,
        fullscreen: true,
        button: true,
        settings: {
          value: "",
          autoRefresh: true,
          mode: "application/x-cypher-query",
          readOnly: false,
          indentWithTabs: false,
          smartIndent: false,
          lineNumbers: true,
          matchBrackets: true,
          autofocus: true,
          lint: true,
          styleActiveLine: true,
          extraKeys: {
            Tab: "autocomplete",
          },
          hintOptions: {
            completeSingle: false,
            closeOnUnfocus: false,
            alignWithWord: true,
            async: true,
          },
          gutters: ["cypher-hints"],
          lineWrapping: true,
          autoCloseBrackets: {
            explode: "",
          },
          neo4jSchema: {
            consoleCommands: [],
            labels: [],
            relationshipTypes: [],
            parameters: [],
            propertyKeys: [],
            functions: [],
            procedures: [],
          },
        },
      },
      db: {
        connected: false,
        populated: false,
      },
      test: {
        busy: false,
        value: false,
        icons: {
          null: "",
          true: "mdi-check-circle-outline",
          false: "mdi-alert-circle-outline",
        },
        uri: null,
        credentials: null,
      },
      mounted: false,
    };
  },

  methods: {
    form_reset_validation() {
      this.test = {
        ...this.test,
        uri: null,
        credentials: null,
      };
    },

    db_settings_open() {
      if (!this.form.enabled) this.form.enabled = true;
    },

    db_settings_close() {
      this.form.enabled = false;
    },

    db_settings_test() {
      this.neo4j.error = {};
      this.test.busy = true;

      const test = {
        uri: null,
        credentials: null,
      };

      return this.neo4j
        .test(
          this.form.values.uri,
          this.form.values.username,
          this.form.values.password
        )
        .then((r) => {
          test.uri = true;
          test.credentials = true;

          this.test.value = true;
          return true;
        })
        .catch((e) => {
          if (this.mounted) {
            switch (e.code) {
              case "Neo.ClientError.Security.Unauthorized":
              case "Neo.ClientError.Security.AuthenticationRateLimit":
                test.credentials = false;
                break;
              default:
                test.uri = false;
                break;
            }
            this.neo4j.error = e;
          }
          this.test.value = false;
          return false;
        })
        .finally(() => {
          this.test.busy = false;
          this.test = {
            ...this.test,
            ...test,
          };

          if (typeof this.$refs.form !== "undefined")
            this.$refs.form.validate();
        });
    },

    db_settings_apply() {
      localStorage.DB = JSON.stringify({
        uri: this.form.values.uri,
        username: this.form.values.username,
        password: this.form.values.password,
      });

      this.neo4j.setup(
        this.form.values.uri,
        this.form.values.username,
        this.form.values.password
      );

      this.preload();
    },

    db_query_apply() {
      this.editor.settings.value = this.editor.value.getValue();
      if (this.editor.settings.value.length === 0) return;
      this.form.values.preload = this.editor.settings.value;
      localStorage.Query = JSON.stringify({
        preload: this.form.values.preload,
      });
      this.page = 0;
    },

    preload() {
      const types = ["Resource"];
      let resources = [];

      this.form.loading = true;

      Promise.all([
        this.neo4j.run(this.form.values.preload, false).then((elements) => {
          resources = elements.Graph.map((r) => {
            const classification = r.classes
              .filter((c) => types.indexOf(c) != -1)
              .concat("")[0];

            const id =
              typeof r.data.properties.id !== "undefined"
                ? r.data.properties.id
                : r.data.name;

            const tags = ["Tags", "TagList"]
              .reduce((O, k) => {
                return [
                  ...O,
                  ...(typeof r.data.properties[k] === "undefined"
                    ? []
                    : JSON.parse(r.data.properties[k])),
                ];
              }, [])
              .reduce((O, o) => {
                let k = o.Key;
                let v = o.Value;
                O[k] = v;
                return O;
              }, {});

            return {
              name: r.data.name,
              id: id,
              type: r.data.type,
              class: classification,
              tags: tags,
              element: r,
            };
          }).sort((a, b) => {
            let c = types.indexOf(a.class) - types.indexOf(b.class);
            if (c !== 0) return c;
            else return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
          });
        }),
      ])
        .then(() => {
          this.db.connected = true;
          this.db.populated = resources.length > 0;
          this.form.values.status = `Connected to ${
            this.neo4j.auth.uri.split("/")[2]
          }`;

          this.$emit("resources", resources);
        })
        .catch((e) => {
          this.db.connected = false;
          this.form.values.status = "Disconnected";
        })
        .finally(() => {
          let page = 0;
          this.form.loading = false;

          if (this.db.connected && this.db.populated) this.form.enabled = false;
          else if (this.db.connected) page = 1;

          this.page = page;
        });
    },

    editor_autocomplete(cm, changed) {
      if (changed.text.length !== 1) {
        return;
      }
      const text = changed.text[0];
      const shouldautocomplete =
        text === "." ||
        text === ":" ||
        text === "[]" ||
        text === "()" ||
        text === "{}" ||
        text === "[" ||
        text === "(" ||
        text === "{" ||
        text === "$";
      if (shouldautocomplete) {
        cm.execCommand("autocomplete");
      }
    },

    editor_query_load(cypher) {
      const lines = cypher.split("\n");
      this.editor.value.setValue(cypher);
      this.editor.value.setCursor({
        line: lines.length,
        ch: lines.splice(-1).length,
      });
    },
  },
  watch: {
    "form.values.uri"() {
      this.test.value = false;
      this.form_reset_validation();
    },
    "form.values.username"() {
      this.test.value = false;
      this.form_reset_validation();
    },
    "form.values.password"() {
      this.test.value = false;
      this.form_reset_validation();
    },
    page(number) {
      if (number === 2 && this.editor.value === null) {
        this.$nextTick(() => {
          const { editor, editorSupport } = CypherCodeMirror.createCypherEditor(
            document.getElementById("db-connection-query"),
            this.editor.settings
          );
          editor.setOption("theme", "cypher");
          // editorSupport.setSchema(this.editor.settings.neo4jSchema);
          editor.on("change", this.editor_autocomplete);

          this.editor.value = editor;
          this.editor_query_load(this.form.values.preload);
        });
      }
    },
  },
  computed: {
    rules() {
      const rules = {
        uri: [this.test.uri === null || this.test.uri],
        credentials: [this.test.credentials === null || this.test.credentials],
      };

      return rules;
    },
  },

  mounted() {
    // this.mounted = true;

    if ("DB" in localStorage) {
      const auth = JSON.parse(localStorage.DB);
      this.form.values.uri = auth.uri;
      this.form.values.username = auth.username;
      this.form.values.password = auth.password;
    }

    if ("Query" in localStorage) {
      const query = JSON.parse(localStorage.Query);
      this.form.values.preload = query.preload;
    }

    this.mounted = false;

    this.db_settings_test()
      .then((success) => {
        if (success) {
          this.neo4j.setup(
            this.form.values.uri,
            this.form.values.username,
            this.form.values.password
          );
          // this.preload();
        }
      })
      .finally(() => {
        this.mounted = true;
      });
  },
};
</script>


<style>
@import "../codemirror-cypher/cypher-codemirror.css";

.CodeMirror {
  max-height: 15vh;
  border: 1px solid rgba(25, 118, 210, 0.3);
  border-radius: 4px 4px;
}

.CodeMirror-vscrollbar {
  right: 2px;
  top: 12px;
}

.CodeMirror {
  height: 15vh !important;
}

.db-settings .mdi-check-circle-outline::before {
  color: #4caf50;
}
.db-settings .mdi-alert-circle-outline::before {
  color: #ff5252;
}
</style>
